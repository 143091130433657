import './sass/app.scss'
import './bootstrap.ts'

import { VartionUI } from '@vartion/ui'
import { BarChart, LineChart, PieChart, ScatterChart } from 'echarts/charts.js'
import { DataZoomComponent, LegendComponent, LegendScrollComponent, PolarComponent, SingleAxisComponent, TitleComponent, TooltipComponent } from 'echarts/components.js'
import { use } from 'echarts/core.js'
import { CanvasRenderer } from 'echarts/renderers.js'
import { createApp } from 'vue'
import VChart from 'vue-echarts/csp'

import App from './App.vue'
import { can, hasRole } from './helpers/permissions.ts'
import router, { route } from './router.ts'
import { bus } from './services/bus.ts'
import { Cache } from './services/cache/Cache.ts'
import { instance as i18n, setLocale, tk } from './services/i18n.ts'
import { sentry } from './services/sentry.ts'
import { useUpdateChecker } from './services/updateChecker.ts'
import { pinia, stores } from './stores/index.ts'
import type { AppName, SomeOtherString } from './types.ts'

use([
  CanvasRenderer,
  BarChart,
  DataZoomComponent,
  LineChart,
  PieChart,
  ScatterChart,
  LegendComponent,
  LegendScrollComponent,
  PolarComponent,
  SingleAxisComponent,
  TitleComponent,
  TooltipComponent,
])

const app = createApp({ ...App, name: 'Pascal' })

// @ts-expect-error - we build the global properties afterwards.
app.config.globalProperties = reactive({})
window.app = app
window.$t = i18n.global.t
window.$te = i18n.global.te
window.router = router

VartionUI.install(app, {
  router,
  httpClient: api,
  config: {
    VForm: { validateOn: 'blur' },
  },
})

app.use(i18n)
app.use(pinia)
app.use(stores)
app.use(router)
app.component('VChart', VChart)

// Load the json file for the fallback locale.
window.localePromise = setLocale('en-gb')

app.config.globalProperties.$tk = window.$tk = tk
app.config.globalProperties.$bus = window.$bus = bus
app.config.globalProperties.$user = window.$user = window.stores.user.user
app.config.globalProperties.$users = window.$users = window.stores.users.users
app.config.globalProperties.$groups = window.$groups = window.stores.groups.groups
app.config.globalProperties.$env = window.$env = window.stores.initialState.env
app.config.globalProperties.$can = window.$can = can
app.config.globalProperties.$hasRole = window.$hasRole = hasRole
app.config.globalProperties.$inAdministration = false

// Indicates if we are in a safe ghost mode which means we cannot make changes to the current organization.
app.config.globalProperties.$inSafeGhostMode = false

// @ts-expect-error computed is unref'd in reactive
app.config.globalProperties.$availableApps = window.$availableApps = computed((): Partial<Record<AppName, string>> => {
  const apps: Partial<Record<AppName, string>> = {}

  const organization = window.stores.organization

  if (organization.has_screening) {
    apps.screening = $t('screening')
  }
  if (organization.has_onboarding) {
    apps.onboarding = $t('onboarding')
  }
  if (organization.has_transaction_monitoring) {
    apps.transaction_monitoring = $t('transaction-monitoring')
  }

  if (can('administrations.view')) {
    apps.spectrum = 'Spectrum'
  }

  return apps
})

app.config.globalProperties.$hasApp = window.$hasApp = (appName: AppName | SomeOtherString) => appName in window.$availableApps.value

// @ts-expect-error computed is unref'd in reactive
app.config.globalProperties.$currentApp = computed(() =>
  app.config.globalProperties.$inAdministration
    ? 'spectrum'
    : route.value.path.startsWith('/client-onboarding/')
      ? 'onboarding'
      : route.value.path.startsWith('/transaction-monitoring/')
        ? 'transaction_monitoring'
        : 'screening',
)

// @ts-expect-error computed is unref'd in reactive
// Indicates if we are in ghost mode which allows administration users to access other organisations.
app.config.globalProperties.$inGhostMode = computed(() => {
  if (!window.stores.user.authenticated) {
    return false
  }
  if (!window.stores.organization.id) {
    return false
  }

  if (app.config.globalProperties.$inAdministration) {
    return false
  }

  if (window.stores.user.user.organizations.some((organization) => organization.id === window.stores.organization.id)) {
    return false
  }

  if (!$can('administrations.ghostMode')) {
    return false
  }

  return true
})

watch(
  () => app.config.globalProperties.$inGhostMode,
  (inGhostMode) => {
    app.config.globalProperties.$inSafeGhostMode = inGhostMode
    Cache.tags('globals').put('inGhostMode', inGhostMode)
  },
)

app.config.performance = import.meta.env.DEV

useUpdateChecker()

// Sentry needs to be initialized before the app is mounted.
sentry.init().then(() => app.mount('#app'))
