import { RequestError } from '@vartion/ui'
import { defineStore } from 'pinia'
import { type WatchStopHandle } from 'vue'

import { route } from '~/router.ts'
import { Cache } from '~/services/cache/Cache.ts'
import { type BillingSettings, type Organization } from '~/types.ts'
import { createPaymentMethodStore } from './paymentMethod.ts'

const cache = Cache.tags('planManagement.v3')

interface OrganizationInvitation {
  id: number
  signup_token_id: number
  user_first_name: string
  user_last_name: string
  user_email: string
  organization_name: string
  organization_address: string
  organization_country: string
  organization_industry: string
  organization_city: string
  organization_postal_code: string
  organization_type: 'commercial' | 'developer' | 'trial' | 'free' | 'contract' | null
  organization_trial_convert_to_customer: boolean
  organization_trial_ends_at: string
  organization_startup_programme: boolean
  marketplace_pack_ids: number[]
  expires_at: string
  used_at: null | string
  created_at: string
  updated_at: string
  credits_total_amount: null | number
  credits_type: null | string
  credits_expires_at: null | string
  discounts: { minimum_cost: number; discount_percentage: number; starts_at: string; expires_at: string }[]
}

interface SignupToken {
  id: number
  uuid: string
  name: string
  created_at: string
  updated_at: string
  organization_invitation?: null | OrganizationInvitation
}

export const createPlanManagementStore = (name: string) => {
  let unwatchCache: undefined | WatchStopHandle = undefined
  const paymentMethodStore = createPaymentMethodStore(`${name}.paymentMethod`)()

  return defineStore(name, {
    state: () => ({
      ready: false,
      loading: false,
      token: {
        id: 0,
        uuid: '',
        created_at: '',
        updated_at: '',
      } as SignupToken,
      user: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
      organization: {
        name: '',
        industry: '',
        city: '',
        address: '',
        country: '',
        postal_code: '',
        type: '' as Organization['type'] | '',
        trial_ends_at: null as null | string,
        trial_convert_to_customer: false,
        startup_programme: false,
      },
      selectedPackIds: [] as number[],
      billingSettings: {
        address: '',
        city: '',
        country: '',
        postal_code: '',
        tax_id: '',
      } as Partial<BillingSettings>,
      isInvitation: false,
      trackAPM: false,
      visitedPages: [] as string[],
      discounts: [] as OrganizationInvitation['discounts'],
      credits_total_amount: null as null | number,
      credits_type: null as null | string,
      credits_expires_at: null as null | string,
      applyToExistingCases: false,
    }),

    getters: {
      paymentMethodStore: () => paymentMethodStore,
    },

    actions: {
      async submit() {
        this.loading = true
        const request: Record<string, any> = {
          token: this.token.uuid,
          user: this.user,
          organization: { ...this.organization },
          marketplace_packs: this.selectedPackIds,
        }

        if (this.billingSettings.address) {
          request.billing_settings = this.billingSettings
        }

        if (route.value.query.type === 'microsoft-marketplace' && typeof route.value.query.token === 'string') {
          request.microsoft_subscription_token = route.value.query.token.replaceAll(' ', '+')
        }

        if (route.value.query.hubspot_code) {
          request.hubspot_code = route.value.query.hubspot_code
        }

        const invitation = this.token.organization_invitation
        if (invitation && dayjs(invitation.expires_at).isBefore(dayjs())) {
          $message($t('your-invitation-has-expired-please-contact-our-support'))
        } else {
          try {
            await api.post('organizations', request)
          } catch (error) {
            this.loading = false
            if (error instanceof RequestError && error.response?.status === 429) {
              $message.error($t('you-have-tried-to-make-too-many-organisations-today-try-again-later'))
            }
            if (error instanceof RequestError && error.response?.status === 422) {
              $message.error($t('there-is-an-error-in-one-of-the-inputs-you-provided'))
              throw error
            } else {
              throw error
            }
          }
        }

        this.loading = false
      },

      async createToken() {
        if (!this.token.uuid) {
          const { data } = await api.post<SignupToken>('signup-tokens', { hubspot_code: route.value.query.hubspot_code ?? null })
          this.token = data
        }
        this.paymentMethodStore.setOrganization({ token: this.token.uuid, name: this.organization.name })
      },

      async fetchToken(uuid: string) {
        const { data } = await api.get<SignupToken>(`signup-tokens/${uuid}`)

        if (data.organization_invitation) {
          const invitation = data.organization_invitation
          if (dayjs(invitation.expires_at).isBefore(dayjs())) {
            $message($t('your-invitation-has-expired-please-contact-our-support'))

            return
          }
          this.user.email = invitation.user_email
          this.user.first_name = invitation.user_first_name
          this.user.last_name = invitation.user_last_name
          this.organization.name = invitation.organization_name
          this.organization.address = invitation.organization_address
          this.organization.country = invitation.organization_country
          this.organization.industry = invitation.organization_industry
          this.organization.city = invitation.organization_city
          this.organization.postal_code = invitation.organization_postal_code
          this.organization.type = invitation.organization_type ?? (invitation.organization_trial_ends_at ? 'trial' : 'commercial')
          this.organization.trial_convert_to_customer = invitation.organization_trial_convert_to_customer
          this.organization.trial_ends_at = invitation.organization_trial_ends_at
          this.organization.startup_programme = invitation.organization_startup_programme
          this.selectedPackIds = invitation.marketplace_pack_ids
          this.credits_total_amount = invitation.credits_total_amount
          this.credits_type = invitation.credits_type
          this.credits_expires_at = invitation.credits_expires_at
          this.discounts = invitation.discounts
        }

        this.token = data
        this.paymentMethodStore.setOrganization({ token: data.uuid, name: this.organization.name })

        // This refreshes the component on the page with the prefilled data.
        this.ready = false
        await nextTick()
        this.ready = true
      },

      trackVisitedPages(page: string) {
        if (this.trackAPM && page && !this.visitedPages.includes(page)) {
          this.visitedPages.push(page)

          const category = route.value.query.type === 'microsoft-marketplace' ? 'microsoft-signup' : route.value.query.hubspot_code ? 'hubspot-signup' : 'regular-signup'
          stores.apmEvents.track({ event: `view-${page}-page`, category }, true)
        }
      },

      trackSignupComplete() {
        if (this.trackAPM) {
          const category = route.value.query.type === 'microsoft-marketplace' ? 'microsoft-signup' : route.value.query.hubspot_code ? 'hubspot-signup' : 'regular-signup'
          stores.apmEvents.track({ event: `completed-signup-of-${this.organization.type}-organization`, category }, true)
        }
      },

      async initialize() {
        this.ready = false
        if (unwatchCache) {
          unwatchCache()
        }
        const cacheName = `${name}${stores.organization.id}.v2`
        const state = await cache.get(cacheName)
        this.$reset()
        if (state) {
          this.$patch(state)
          if (this.token.id) {
            this.paymentMethodStore.setOrganization({ token: this.token.uuid, name: this.organization.name })
          } else if (stores.organization.id) {
            this.paymentMethodStore.setOrganization({ id: stores.organization.id })
          }
        }

        // Cache the store so that we can continue after a refresh.
        unwatchCache = watch(
          () => this.$state,
          async (state) => {
            const partialState = copyObject<Partial<typeof state>>(state)
            delete partialState.ready
            delete partialState.loading
            delete partialState.trackAPM
            delete partialState.applyToExistingCases
            await cache.put(cacheName, partialState)
          },
          { deep: true, immediate: true },
        )
        this.ready = true
      },
    },

    init: async (store) => {
      await Promise.all([stores.marketplacePacks.fetch(), stores.prices.fetch()])
      await store.initialize()
    },
  })
}
