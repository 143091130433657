import { RequestError } from '@vartion/ui'
import { acceptHMRUpdate, defineStore } from 'pinia'

import router from '~/router.ts'
import { Cache } from '~/services/cache/Cache.ts'
import { CASE_SOURCE_NAMES, type CaseHit, type CaseModelDetailed, type CaseSource, type CaseSourceName, type CaseType, type HitResolution } from '~/types.ts'
import { type UpdateCaseRequest, useCases } from './cases.ts'
import { createHitsStore } from './hits.ts'
import { withReset } from './index.ts'
import { canReadCase } from './user.ts'

interface User {
  id: number
  name: string
}

export type CaseOrigin = 'person-check' | 'business-check' | 'asset-check' | 'hyperlink'

export const FORM_KEYS = [
  'name',
  'aliases',
  'additional_terms',
  'gender',
  'date_of_birth',
  'nationalities',
  'country_of_birth',
  'country_of_residence',
  'address',
  'country',
  'company_number',
  'asset_type',
  'identifier',
] as const

export interface UpdateCaseParams {
  form?: Record<string, any>
  passportVerificationForm?: Record<string, any>
  origin?: CaseOrigin
  originCaseId?: null | number
}

export interface CreateCaseParams {
  type: CaseType
  form?: Record<string, any>
  passportVerificationForm?: Record<string, any>
  origin?: CaseOrigin
  originCaseId?: null | number
  duplicateCheck?: boolean
}

export interface SearchRequest {
  type: CaseType
  origin?: CaseOrigin
  origin_case_id?: null | number
  duplicateCheck?: boolean
  passportVerificationForm?: Record<string, any>
  case_id?: number
  [key: string]: any
}

export function createCaseStore(storeName: string) {
  return defineStore(storeName, () => {
    const caseId = ref(-1)
    const originCaseId = ref<null | number>(null)
    const activeResolution = ref<HitResolution>('unresolved')
    const searchedName = ref('')
    const users = ref<User[]>([])
    const filterQuery = ref('')
    const filterCreatedAt = ref<string[]>([])
    const showFilters = ref(false)
    const showOnlyUpdatedHits = ref(false)
    const interval = ref<undefined | number>(undefined)

    const cases = useCases()

    const enforcements = createHitsStore('enforcements', storeName)()
    const news = createHitsStore('news', storeName)()
    const other = createHitsStore('other', storeName)()
    const peps = createHitsStore('peps', storeName)()
    const sanctions = createHitsStore('sanctions', storeName)()

    const sourceMap = {
      enforcements,
      news,
      other,
      peps,
      sanctions,
    }

    const $reset = withReset({
      caseId,
      originCaseId,
      activeResolution,
      searchedName,
      users,
      filterQuery,
      filterCreatedAt,
      showFilters,
      showOnlyUpdatedHits,
      interval,
    })

    Cache.tags('UIState')
      .get('resolveFiltersVisible')
      .then((value) => (showFilters.value = value ?? false))

    watch(
      () => showFilters.value,
      (newValue) => Cache.tags('UIState').put('resolveFiltersVisible', newValue),
    )

    const caseModel = computed((): CaseModelDetailed | undefined => cases.opened[caseId.value])

    const name = computed((): string => (caseModel.value ? `${caseModel.value.type} Check` : ''))

    const sources = computed((): CaseSourceName[] => {
      if (caseModel.value?.type === 'Business') {
        return ['sanctions', 'enforcements', 'peps', 'news', 'other']
      }

      if (caseModel.value?.type === 'Person') {
        return ['sanctions', 'enforcements', 'peps', 'news', 'other']
      }

      if (caseModel.value?.type === 'Asset') {
        return ['sanctions', 'enforcements']
      }

      return []
    })

    const isSearching = computed((): boolean => sources.value.some((source) => sourceMap[source].loading.length > 0))

    watch(isSearching, (searching) => {
      if (!searching && caseId.value > 0) {
        cases.loadCase(caseId.value)
      }
    })

    /** Create a case. resets the store if it already contains a case. */
    async function create({ type, form = {}, passportVerificationForm = {}, origin, originCaseId: _originCaseId = null, duplicateCheck = true }: CreateCaseParams) {
      await stores.loading.update({ case: true })

      if (!origin) {
        origin = type === 'Person' ? 'person-check' : type === 'Business' ? 'business-check' : 'asset-check'
      }

      if (!_originCaseId) {
        _originCaseId = originCaseId.value
      } else {
        originCaseId.value = _originCaseId
      }

      reset()

      searchedName.value = form.name

      const postData: SearchRequest = {
        ...form,
        type,
        origin,
        origin_case_id: _originCaseId,
        duplicateCheck,
      }

      if (type === 'Person') {
        postData.passportVerificationForm = passportVerificationForm
      }

      try {
        const { data } = await api.post<CaseModelDetailed>('cases', postData)
        if (!canReadCase(data)) {
          $message.success($t('the-case-was-created-but-you-do-not-have-access-to-view-it'))

          return
        }
        cases.opened[data.id] = data
        updateCaseId(data.id)
      } catch (error) {
        if (error instanceof RequestError) {
          const status = error.response?.status
          const data = error.response?.data as { uuids: string[]; error: string; message: string }
          if (status === 403) {
            await duplicateCasesFound({ ...data, searchData: { type, form, origin, originCaseId: originCaseId.value, passportVerificationForm } })
          } else if (status === 422) {
            $message.error(data.message)
          } else {
            $message.error($t('api-error'))
            console.error(error)
          }
        } else {
          $message($t('api-error'))
          console.error(error)
        }
      } finally {
        await stores.loading.update({ case: false })
      }
    }

    async function update({ form = {}, passportVerificationForm = {} }: UpdateCaseParams) {
      if (!caseModel.value) {
        throw new Error('Missing case to update.')
      }

      await stores.loading.update({ case: true })

      const type = caseModel.value.type
      searchedName.value = form.name

      const payload: UpdateCaseRequest = {
        ...form,
        id: caseId.value,
      }

      if (type === 'Person') {
        payload.passportVerificationForm = passportVerificationForm
      }

      try {
        await cases.update(payload)
        const data = cases.opened[payload.id]

        const activeSources = data.sources.filter((source) => source.active)
        if (activeSources.length > 0) {
          await api.post('case-sources/search', { ids: activeSources.map((source) => source.id), case_id: data.id })
          activeSources.forEach((source) => sourceMap[source.data_source.source].searching(source))
        }
      } catch {
        $message($t('api-error'))
      } finally {
        await stores.loading.update({ case: false })
      }
    }

    async function loadCase(data: CaseModelDetailed) {
      // Reset the state to a clean one before loading in a new state.
      if (caseId.value > 0) {
        reset()
      }

      searchedName.value = data.name

      updateCaseId(data.id)
      await delay(300) // Wait a bit to make sure the page has been created, any awaits on this action can then send events via the bus.

      for (const source of sources.value) {
        sourceMap[source].loadHitsFromCase(data)
      }

      if (data.status !== 'Archived') {
        const unsearched = data.sources.filter((source) => source.active && (!source.searched_at || !source.succeeded))
        if (unsearched.length > 0) {
          try {
            await api.post('case-sources/search', { ids: unsearched.map((source) => source.id), case_id: data.id })
            unsearched.map((source) => sourceMap[source.data_source.source].searching(source))
          } catch {
            // Most likely means there are no searches remaining.
          }
        }
      }
    }

    // Reset the state and the state of all source modules.
    function reset() {
      resetState()

      for (const source of CASE_SOURCE_NAMES) {
        sourceMap[source].$reset()
      }
    }

    function resetState() {
      const previousShowFilters = showFilters.value
      echo.leave(`App.Models.CaseModel.${caseId.value}`)
      clearInterval(interval.value)
      $reset()
      showFilters.value = previousShowFilters
    }

    function updateCaseId(id: number) {
      if (id === caseId.value) {
        return
      }

      if (caseId.value > 0) {
        echo.leaveChannel(`presence-App.Models.CaseModel.${caseId.value}`)
        clearInterval(interval.value)
      }

      caseId.value = id

      if (id > -1) {
        listenForEvents()
      }
    }

    async function duplicateCasesFound({ error, uuids, searchData }: { error: string; uuids: string[]; searchData: CreateCaseParams }) {
      blurAll() // Remove focus on input, because else it searches twice.

      try {
        await $confirm({
          message: $t(
            'a-case-with-the-same-information-you-provided-already-exists-are-you-sure-you-want-to-create-the-same-case-again-or-open-the-identical-existing-case-or-count-cases-with-the-same-information-you-provided-already-exist-are-you-sure-you-want-to-create-the-same-case-again-or-view-the-identical-existing-cases',
            uuids.length,
          ),
          title: error,
          confirmButtonLabel: $t('create-new-case'),
          cancelButtonLabel: uuids.length === 1 ? $t('open-existing-case') : $t('view-existing-cases'),
        })
      } catch (action) {
        if (action === 'cancel') {
          if (uuids.length > 1) {
            await router.push('/cases')
            await delay(300) // Wait for route transition.
            $bus.emit('cases.resetFilters')
            $bus.emit('cases.updateFilters', { uuid: uuids })
          } else {
            await cases.loadCaseFromUUID(uuids[0])
            const item = Object.values(cases.opened).find((item) => item.uuid === uuids[0])
            if (item) {
              loadCase(item)
            }
          }
        } else {
          searchedName.value = ''
        }

        return
      }

      await create({ ...searchData, duplicateCheck: false })
    }

    function listenForEvents() {
      echo.leaveChannel(`presence-App.Models.CaseModel.${caseId.value}`)

      echo
        .join(`App.Models.CaseModel.${caseId.value}`)
        .here((_users: User[]) => (users.value = _users))
        .joining((user: User) => addUser(user))
        .leaving((user: User) => removeUser(user))
        .listen('CaseUpdated', ({ case: data }: { case: CaseModelDetailed }) => cases.updateOpened(data))
        .listen('HitsResolved', ({ risk, source, hits }: { risk: CaseModelDetailed['risk']; source: CaseSourceName; hits: CaseHit[] }) => {
          sourceMap[source].updateHits(hits)
          cases.updateOpened({ updated_at: dayjs().toISOString(), risk, id: caseId.value })
        })
        .listen('CaseAssigned', ({ case: data }: { case: CaseModelDetailed }) =>
          cases.updateOpened({ id: caseId.value, user_id: data.user_id, group_id: data.group_id, updated_at: dayjs().toISOString() }),
        )
        .listen('HitsUpdated', ({ hits }: { hits: CaseHit[] }) => {
          for (const source of CASE_SOURCE_NAMES) {
            const sourceHits = hits.filter((hit) => hit.source === source)
            if (sourceHits) {
              sourceMap[source].updateHits(sourceHits)
            }
          }
        })
        .listen('CaseSourceSearched', ({ hits, source }: { hits: CaseHit[]; source: CaseSource }) => {
          stores.cases.updateOpened({ id: caseId.value, searched_at: dayjs().toISOString() })
          sourceMap[source.data_source.source].loadHitsFromSearch({ source, hits: hits.filter((hit) => hit.data_source_id === source.data_source_id) })
        })
        .listen('CaseSourceSearchFailed', ({ source }: { source: CaseSource }) => {
          const name = source.data_source.source
          $message.error(`Failed to search ${stores.source.labels[name]}`)
          sourceMap[source.data_source.source].setError(source.id)
        })

      clearInterval(interval.value)
      interval.value = setInterval(() => {
        for (const source of sources.value) {
          for (const id of sourceMap[source].loading) {
            sourceMap[source].refreshCaseSource(id)
          }
        }
      }, 5000) as unknown as number
    }

    function addUser(user: User) {
      if (users.value.findIndex((item) => item.id === user.id) === -1) {
        users.value.push(user)
      }
    }

    function removeUser(user: User) {
      const index = users.value.findIndex((item) => item.id === user.id)
      if (index >= 0) {
        users.value.splice(index, 1)
      }
    }

    function dispose() {
      for (const source of CASE_SOURCE_NAMES) {
        sourceMap[source].$dispose()
      }
    }

    return {
      activeResolution,
      case: caseModel,
      caseId,
      create,
      dispose,
      enforcements,
      filterCreatedAt,
      filterQuery,
      interval,
      isSearching,
      loadCase,
      name,
      news,
      originCaseId,
      other,
      peps,
      reset,
      sanctions,
      searchedName,
      showFilters,
      showOnlyUpdatedHits,
      sources,
      update,
      users,
    }
  })
}

export const useCase = createCaseStore('case')
export const useHyperlinkCase = createCaseStore('hyperlinkCase')

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCase, import.meta.hot))
  import.meta.hot.accept(acceptHMRUpdate(useHyperlinkCase, import.meta.hot))
}
